<template>
  <div class="add-users">
    <div class="top-form">
      <h3>统一信息</h3>
      <a-form-model ref="topForm" layout="inline" :model="formModel" :rules="rules">
        <a-form-model-item label="注册区域" prop="location">
          <a-cascader
            :options="options"
            :fieldNames="{
              label: 'district',
              value: 'district',
              children: 'children',
            }"
            v-model="formModel.location"
            placeholder="请选择"
          />
        </a-form-model-item>
        <a-form-model-item label="默认密码" prop="password">
          <a-input v-model="formModel.password" type="password" placeholder="请输入默认密码"> </a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="服务码（选填）" prop="code">
          <a-input v-model="formModel.code" placeholder="请输入服务码（选填）"> </a-input>
        </a-form-model-item> -->
        <a-form-model-item label="人员类别">
          <a-select
            style="width: 200px"
            @change="handleChangeAllType"
            placeholder="请选择人员类别"
            v-model="formModel.allType"
            allowClear
          >
            <a-select-option v-for="item in personOption" :value="item.id" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="formModel.allType == 2 ? '专业' : '工种'">
          <a-select
            style="width: 200px"
            allowClear
            @change="handleChangeAllPon"
            :placeholder="`请选择${formModel.allType == 2 ? '专业' : '工种'}`"
            v-model="formModel.allPositionId"
          >
            <a-select-option v-for="item in getPositionOption(formModel.allType)" :value="item.id" :key="item.id">
              {{ item.majorName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="bottom-form">
      <h3>注册账号信息（至少填写一条）</h3>
      <a-form-model
        ref="bottomForm"
        class="user-form"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 20 }"
        layout="inline"
        :model="formModel"
        :rules="rules"
      >
        <a-row>
          <a-col
            :span="12"
            v-for="(item, index) in formModel.userList"
            :key="item.id"
            style="padding-left: 10px; height: 60px"
          >
            <div class="index-label">{{ item.id }}</div>
            <a-form-model-item
              :prop="'userList.' + index + '.value'"
              :help="getErrorMsg(item.id)"
              :validateStatus="getErrorMsg(item.id) ? 'error' : 'success'"
            >
              <a-input v-model="item.value" placeholder="请输入账号"> </a-input>
            </a-form-model-item>
            <a-form-model-item :prop="'userList.' + index + '.type'">
              <a-select
                style="width: 168px; margin-right: 34px"
                @change="handleChangeType.call(null, $event, index)"
                placeholder="请选择人员类别"
                allowClear
                v-model="item.type"
              >
                <a-select-option v-for="item in personOption" :value="item.id" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :prop="'userList.' + index + '.positionId'">
              <a-select
                style="width: 168px"
                allowClear
                :placeholder="`请选择${getLabel(index)}`"
                v-model="item.positionId"
              >
                <a-select-option v-for="item in getPositionOption(item.type)" :value="item.id" :key="item.id">
                  {{ item.majorName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="btn-box">
      <a-button type="primary" @click="addUserList(10)">注册账号信息添加</a-button>
      <a-button type="" @click="handleReset">重置</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">新增</a-button>
    </div>
  </div>
</template>

<script>
import { getTree } from '@/api/location'
import { batchRegisterForApp } from '@/api/system'
import { employmentEmploypersonnelType, employmentEmployDictPosition } from '@/api/workDemand'

const pattern = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

export default {
  name: 'AddUser',
  data () {
    return {
      formModel: {
        userList: [],
        allType: undefined,
        allPositionId: undefined
      },
      rules: {
        location: [{ required: true, message: '请选择注册区域', trigger: 'change' }],
        password: [
          { required: true, message: '请输入默认密码', trigger: 'change' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/, message: '密码必须由数字+字母组成且长度为8~12位' }
        ]
      },
      options: [],
      personOption: [],
      positionOption1: [],
      positionOption2: [],
      errFields: [],
      loading: false
    }
  },
  created () {
    this.init()
    this.addUserList(10)
    getTree().then((res) => {
      this.options = res
    })
  },
  methods: {
    init () {
      employmentEmploypersonnelType() // 人员类别
        .then((res) => {
          this.personOption = res.data
        })
      employmentEmployDictPosition({ type: 1 }).then((res) => {
        this.positionOption1 = res.data
      })
      employmentEmployDictPosition({ type: 2 }).then((res) => {
        this.positionOption2 = res.data
      })
    },
    handleReset () {
      this.$refs.topForm.resetFields()
      this.$refs.bottomForm.resetFields()
      this.$set(this, 'formModel', {
        userList: []
      })
      this.addUserList(10)
    },
    async validateBottom () {
      const userList = this.formModel.userList.filter((item) => item.value && item.type && item.positionId)
      this.errFields = []
      if (userList.length) {
        userList.forEach((item) => {
          const { value, id } = item
          const errField = {}
          if (!pattern.test(value)) {
            errField.value = '手机号格式不正确'
            errField.id = id
            this.errFields.push(errField)
          }
        })
      }
      return !this.errFields.length
    },
    async handleSubmit () {
      const tr = await this.$refs.topForm.validate()
      const br = await this.validateBottom()
      if (tr && br) {
        const vr = this.formModel.userList.filter((item) => item.value && item.type && item.positionId)
        if (!vr.length) {
          this.$message.error('请输入至少一条完整账号信息')
          return
        } else {
          const { code = '', password, location } = this.formModel
          const data = vr.map((item) => ({
            username: item.value,
            code,
            password,
            location: location.join('-'),
            enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
            labelAddCmd: {
              type: item.type,
              positionId: item.positionId
            }
          }))
          this.loading = true
          batchRegisterForApp(data)
            .then(() => {
              this.$message.success('账号新增成功')
              this.$router.push('/system/app-users')
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
      console.log(tr, br)
    },
    addUserList (num = 0) {
      const userList = []
      const userListLength = this.formModel.userList.length
      for (let i = 1; i <= num; i++) {
        userList.push({
          id: userListLength + i,
          value: '',
          type: undefined,
          positionId: undefined
        })
      }
      this.$set(this.formModel, 'userList', [...this.formModel.userList, ...userList])
    },
    handleChangeAllType (value) {
      this.formModel.allPositionId = undefined
      if (value) {
        this.formModel.userList.forEach((item, index) => {
          this.$set(this.formModel.userList, index, {
            ...item,
            type: value,
            positionId: undefined
          })
        })
      }
    },
    handleChangeAllPon (value) {
      if (value) {
        this.formModel.userList.forEach((item, index) => {
          this.$set(this.formModel.userList, index, {
            ...item,
            type: this.formModel.allType,
            positionId: value
          })
        })
      }
    },
    handleChangeType (value, index) {
      this.$set(this.formModel.userList[index], 'positionId', undefined)
    },
    getLabel (index) {
      const data = this.formModel.userList[index].type
      return data === '2' ? '专业' : '工种'
    },
    getPositionOption (type) {
      if (!type) return []
      return this[`positionOption${type}`]
    },
    getErrorMsg (id) {
      const data = this.errFields.find((item) => item.id === id)
      return data ? data.value : ''
    }
  }
}
</script>

<style lang="less">
.add-users {
  background: #ffffff;
  height: 100%;
  padding: 24px;
  overflow: auto;
  h3 {
    font-weight: 550;
    font-size: 18px;
  }
  .top-form {
    height: 100px;
    border-bottom: 1px solid #cccccc;
    position: relative;
  }
  .btn-box {
    height: 50px;
    text-align: right;
    line-height: 50px;
  }
  .bottom-form {
    margin-top: 24px;
    height: calc(100% - 175px);
    overflow: auto;
  }
  .user-form {
    .ant-form-item {
      margin-right: 0;
    }
  }
  .add-btn {
    margin-top: 24px;
    text-align: center;
  }
  .index-label {
    display: inline-block;
    width: 40px;
    text-align: right;
    padding-right: 10px;
    line-height: 40px;
  }
  .ant-form-item-with-help {
    margin-bottom: 0px !important;
  }
}
</style>
